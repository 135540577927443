import { object, string, array, boolean } from "yup";

export const userSchema = object({
  id: string().optional().nullable(),
  name: string().optional().min(0).max(255).label("User Name"),
  email: string()
    .matches(
      /^(?!.*(@cbre\.com|@cbreim\.com).*\1)(.*(@cbre.com|@cbreim.com))$/,
      "Email should be in the correct format with CBRE domain",
    )
    .required("Email is a required field")
    .email()
    .max(255)
    .label("Email"),
  role: string().required().label("Role"),
  clients: array().optional().label("Clients"),
  regions: array().optional().label("Regions"),
  is_active: boolean().label("Activate User"),
});
